import React, { useState, useEffect } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material'
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

function Index() {
    const [contacts, setContacts] = useState([]);
    
    useEffect(() => { 
        const data = require('./contacts.json')
        const sorted = data.sort(function (a, b) {
            let x = a.department.toUpperCase();
            let y = b.department.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
        });
        setContacts(sorted);
    }, []);

    return (
        <div>
            <Typography color="text.primary">Contatos</Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {contacts.map((contact, index) => {
                    return(
                        <div key={index}>
                            <ListItem dense={true}>
                                <ListItemAvatar>
                                    <Avatar sx={{ bgcolor: '#5684BF' }}>
                                        <ContactPhoneIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={
                                        <React.Fragment>
                                                <Typography
                                                component="p"
                                                variant="p"
                                                color="text.primary"
                                            >
                                                {contact.department}
                                            </Typography>
                                        </React.Fragment>
                                    }
                                    secondary={
                                        <React.Fragment>
                                            {contact.number}
                                        </React.Fragment>
                                    }
                                />  
                            </ListItem>
                            <Divider component="li" />
                        </div>
                    )       
                })} 
            </List>
        </div>
  );
}

export default Index;

