import {
    Skeleton
} from '@mui/material';

const News = ({item}) => {
    return (
        <>
            {item.title ?  
                (<a href={`/news/${item.id}`}><img src={item.has_video ? '/video.png' : item.attachment}

                        alt={item.title}
                        width={"100%"}
                        height={"100%"}
                        style={{ objectFit: 'cover', backgroundColor: 'rgba(0, 0, 0, 0.9)'}}
                        loading="lazy"/></a>) 
            : (<Skeleton 
                variant="rectangular"
                width={"100%"}
                height={"100%"}
                animation="wave" />
            )}
        </>
    )
}

export default News