import React, { useState, useEffect } from 'react';
import { styled, alpha } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Toolbar, Button, Avatar, Link } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
// import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
// import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import WorksIcon from '@mui/icons-material/ArticleOutlined';
import Badge from '@mui/material/Badge';
import MainDrawer from './MainDrawer';
import UserDrawer from './UserDrawer';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '40ch',
      '&:focus': {
        width: '50ch',
      },
    }
  },
}));

export default function SearchAppBar() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const [avatar, setAvatar] = useState("#");
  const [userName, setUserName] = useState("");

  useEffect(() => { 
    setAvatar(localStorage.getItem('avatar'))
    setUserName(localStorage.getItem('name')) 
}, []);
  
  const handleSearch = (e) => {
    let term = e.target.value
    if(e.keyCode === 13 && term !== ''){
      window.location.replace(`/search/${term}`);
    }
  }
  
  return (
    <Box sx={{ flexGrow: 1, mb: 10}}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="open drawer"
            sx={{ mr: 2 }}
            onClick={ () => setOpenDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <Link href="/" underline="none">
            <Avatar
              alt="logo-corregedoria"
              src="/logo.png"
              sx={{ width: 48, height: 48, mr: 1, display: { xs: 'none', sm: 'block' } }}
            />
          </Link>

          <Typography
            onClick={() => window.location.replace("/")}
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: { xs: 'none', sm: 'none', md: 'block' }, cursor: 'pointer' }}
          >
            Intranet
          </Typography>
          
          <Search sx={{ mr: 2}} >
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Pesquisar…"
              onKeyDown={ (e) => handleSearch(e)}
              inputProps={{ 'aria-label': 'search' }}
            />
          </Search>
          <Box component="div" sx={{ ml: 3, mr: 4, display: { xs: 'none', sm: 'none', md: 'none', lg: 'inline' }}}>
            <Button 
              href="/works"
              color="inherit" 
              startIcon={<WorksIcon />}>
              Escalas
            </Button>
            <Button 
              href="/bi"
              color="inherit" 
              startIcon={<StickyNote2Icon />}>
              BI
            </Button>

            <Button 
              href="/contacts"
              color="inherit" 
              startIcon={<ContactPhoneIcon />}>
              Contatos
            </Button>
            {/* <Button color="inherit" startIcon={<DashboardOutlinedIcon />} disabled>
              Sistemas
            </Button>
            <Button color="inherit" startIcon={<ContactPhoneOutlinedIcon />} disabled>
              Contatos
            </Button> */}
          </Box>
          <IconButton onClick={ () => setOpenUserDrawer(true)}>
            <StyledBadge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              variant="dot"
              sx={{ marginRight: 2}}
            >
              <Avatar alt={userName} src={avatar} />
            </StyledBadge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <MainDrawer setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
      <UserDrawer setOpenDrawer={setOpenUserDrawer} openDrawer={openUserDrawer} />
    </Box>
  );
}

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));
