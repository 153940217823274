import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import {Typography, Box, Skeleton, Container, Fab} from '@mui/material';
import News from './News'

function Index({news}) {

  return (
    <Container sx={{p: '0px !important'}}>
      <ImageList 
          variant="quilted"
          cols={4}
          rows={4}
          gap={6}
          sx={{
            overflow: 'hidden',
            transform: 'translateZ(0)',
            gridTemplateColumns:
              'repeat(auto-fill, minmax(200px, 1fr))!important',
          }}
          rowHeight={200}>
        {news.map((item, index) => (
          <ImageListItem key={index}
            cols={index === 0 ? 2 : 1}
            rows={index === 0 ? 2 : 1}>
            
            <News item={item} />
            
            <ImageListItemBar
              title={item.title ? <div style={{ whiteSpace: 'normal'}}>
                  <Typography variant={index === 0 ? "h5" : "p"}>{item.title}</Typography>
                  </div> : <span><Skeleton /><Skeleton /></span>}
              actionIcon={
                <IconButton
                  href={`news/${item.id}`}
                  sx={{ color: 'rgba(255, 255, 255, 0.54)' }}
                  aria-label={`info about ${item.title}`}
                >
                  <InfoIcon />
                </IconButton>
              }
            />
          </ImageListItem>
        ))}
      </ImageList>
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
        <Fab variant="extended" href="/news" color="secondary" size="medium" aria-label="more">
          <small>Ver mais</small>
         </Fab>
      </Box>
    </Container>
  );
}

export default Index;