import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import WorksIcon from '@mui/icons-material/ArticleOutlined';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

export default function TemporaryDrawer(props) {
  const { setOpenDrawer, openDrawer } =  props

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setOpenDrawer(false)
  };

  const redirectTo = (path) => {
    window.location.replace(path);
  }

  const list = (anchor) => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={(e) => toggleDrawer(e)}
      onKeyDown={(e) => toggleDrawer(e)}
    >
      <List sx={{ mt: 4}}>
        <ListItem button onClick={ () => redirectTo("/news")}>         
          <ListItemIcon>
              <DescriptionIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Notícias" />
        </ListItem>
          
        <ListItem button onClick={ () => redirectTo("/posts")}>
          <ListItemIcon>
              <ArticleIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"Publicações"} />
        </ListItem>

        <ListItem button onClick={ () => redirectTo("/bi")}>
          <ListItemIcon>
              <StickyNote2Icon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"Boletim Interno"} />
        </ListItem>

        <ListItem button onClick={ () => redirectTo("/departments")}>
          <ListItemIcon>
              <AccountTreeIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"Departamentos"} />
        </ListItem>

        <ListItem button onClick={ () => redirectTo("/contacts")}>
          <ListItemIcon>
              <ContactPhoneIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"Contatos"} />
        </ListItem>
      </List>
      
      <Divider />

      <List>
        <ListItem button onClick={ () => redirectTo("/works")}>
          <ListItemIcon>
            <WorksIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary={"Escalas"} />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
        <Drawer
        anchor='left'
        open={openDrawer}
        onClose={ (e) => toggleDrawer(e)}
        >
        {list('left')}
        </Drawer>
    </React.Fragment>
  );
}
