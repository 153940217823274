import React from 'react';
import {Grid, Box, Container, Fab} from '@mui/material'
import Post from './Post'

export default function Index({posts}) {
    return (
        <>
            <Grid container
                direction="row"
                justifyContent="center"
                alignItems="flex-start" 
                spacing={1}>
                    {posts.map((post, index) => {
                        return(
                            <Grid item key={index}>
                                <Post post={post} />
                            </Grid>
                        )
                    })}
            </Grid>
            <Container sx={{mt: 3}}>
                <Box display="flex" justifyContent="flex-end" alignItems="flex-end">
                    <Fab variant="extended" href="/posts" color="secondary" size="medium" aria-label="more">
                        <small>Ver mais</small>
                    </Fab>
                </Box>
            </Container>
       </>
    )
}