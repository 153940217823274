import React, { useEffect } from 'react';
import Divider from '@mui/material/Divider';
import Rating from '@mui/material/Rating';
import { Typography } from '@mui/material'

const QuestionItem = (props) => {
    const { question, setScore, isOpen } = props
    const [value, setValue] = React.useState(0);

    useEffect(() => {
        setValue(0) 
    }, [isOpen]);

    const handleValues = (id, value) => {
        setValue(value)
        setScore(id, value)
    }

    return (
        <div>
            <Divider sx={{marginBottom: 1}}/>
            <Typography color="text.primary">{question.content}</Typography>
            <Rating
                name="simple-controlled"
                value={value}
                precision={0.5}
                size="large"
                sx={{ marginBottom: 2}}
                onChange={ (event, newValue) => handleValues(question.id, newValue) }
            />
        </div>
    );
}

export default QuestionItem;