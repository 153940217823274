import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';

function Index() {
    const [contacts, setContacts] = useState([]);
    
    useEffect(() => { 
        const data = require('./contacts.json')
        const sorted = data.sort(function (a, b) {
            let x = a.department.toUpperCase();
            let y = b.department.toUpperCase();
            return x === y ? 0 : x > y ? 1 : -1;
        });
        setContacts(sorted);
    }, []);

    return (
        <div>
            <Grid container spacing={2} sx={{ mb: 2}}>
                <Grid item sm={8} xs={12}>
                    <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 2}}>
                        <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItems: 'center' }}>
                            <HomeIcon sx={{ mr: 0.5 }} />
                            Página inicial
                        </Link>
                        <Typography color="text.primary">Contatos</Typography>
                    </Breadcrumbs>
                    <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        {contacts.map((contact, index) => {
                            return(
                                <div key={index}>
                                    {/* <ListItemButton component="a" href={`/news/${news.id}`} dense={true}> */}
                                        <ListItem alignItems="flex-start" dense={true}>
                                            <ListItemAvatar>
                                                <Avatar sx={{ bgcolor: '#5684BF' }}>
                                                    <ContactPhoneIcon />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <React.Fragment>
                                                         <Typography
                                                            component="p"
                                                            variant="p"
                                                            color="text.primary"
                                                        >
                                                            {contact.department}
                                                        </Typography>
                                                    </React.Fragment>
                                                }
                                                secondary={
                                                    <React.Fragment>
                                                        {contact.number}
                                                    </React.Fragment>
                                                }
                                            />  
                                        </ListItem>
                                    {/* </ ListItemButton> */}
                                    <Divider variant="inset" component="li" />
                                </div>
                            )       
                        })} 
                    </List>
                </Grid>
            </Grid>
        </div>
  );
}

export default Index;

