import React, {useEffect, useState} from 'react';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import DescriptionIcon from '@mui/icons-material/Description';
import ArticleIcon from '@mui/icons-material/Article';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import Paper from '@mui/material/Paper';

const components = [
  {label: "Notícias", value: "Notícias", icon: <DescriptionIcon />, path: '/news'},
  {label: "Publicações", value: "Publicações", icon: <ArticleIcon />, path: '/posts'},
  {label: "BI", value: "BI", icon: <StickyNote2Icon />, path: '/bi'},
  {label: "Contatos", value: "Contatos", icon: <ContactPhoneIcon />, path: '/contacts'}
]

export default function MenuBottom() {
  const [value, setValue] = useState(null);

  useEffect(() => {
    let pathname = window.location.pathname
    if(pathname === "/" || pathname === "/home"){
      pathname = '/news'
    }
    setValue(pathname)
  }, []);

  const bottomNavigationOnChange = (newPath) => {
    window.location.replace(newPath);
  }

  return (
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={4}>
      <BottomNavigation value={value} 
                        showLabels={true}
                        onChange={(e, newPath) => {bottomNavigationOnChange(newPath)}}>
        {components.map(function(item, index) {
          return (<BottomNavigationAction
                    key={index}
                    value={item.path}
                    label={item.value} 
                    icon={item.icon} />)
        })}
      </BottomNavigation>
    </Paper>);
}
