import * as React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import {Avatar, Skeleton} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DownloadIcon from '@mui/icons-material/Download';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';


export default function RecipeReviewCard({post}) {
  
  return (
    <Card raised={true} sx={{ width: 390 }} >
      <CardHeader
        avatar={
          <Avatar alt="logo-corregedoria" src="/logo.png" />
        }
        title={post.title ? post.title : <Skeleton />}
        subheader={post.department ? `${post.department} - ${post.datetime}` : <Skeleton />}
      />
      <CardActions disableSpacing>
        <IconButton 
          href={post.attachment} 
          aria-label="attachment"
          disabled={post.attachment ? false : true}>
          <DownloadIcon color={post.attachment ? 'primary' : ''} />
        </IconButton>
        <IconButton aria-label="show" disabled>
          <RemoveRedEyeIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
}
