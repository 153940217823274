import React from 'react';
import { Skeleton } from '@mui/material'

function CustomSkeleton({ size }) {
    return (
        <>
            {
                [...Array(size).keys()].map((_, index) => {
                    return(
                        <Skeleton key={index} variant="rectangular" height={80} sx={{mb: 2}}  />
                    )
                })
            }    
        </>
  );
}

export default CustomSkeleton;

