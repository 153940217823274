import React from 'react';

import {Alert} from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

export default function Annotations(props) {
  const { annotations } = props
  
  return (
        <>
            {annotations.map((annotation, index) => {
                return(
                    <Alert key={index} sx={{mt: 2}} 
                        severity="warning"
                        iconMapping={{
                            success: <InfoOutlinedIcon fontSize="inherit" />,
                        }}>
                        {annotation.content}
                    </Alert>
                )
            })}
      </>
  );
}
