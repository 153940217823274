import React from 'react';
import {Alert, Box} from '@mui/material';

import Works from './works';

export default function Members(props) {
  const { members } = props

  const sortDescByTotal = (data) => {
    let result = data.sort((a, b) => {
      if (a.total < b.total) {
        return -1;
      }
    });
    return result.reverse()
  }
  
  return (
      <>
        {sortDescByTotal(members).map((member, index) => {
          return(
            <Box sx={{mt: 2}} key={index}>
              <Alert
                severity="info"
                icon={false} >
                {member.job_title} {member.origin}
                <strong> {member.name}</strong>
              </Alert>
              <Works works={member.works} total={member.total} />
            </Box>
          )
        })}
    </>
  );
}
