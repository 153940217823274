import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout';
import PieChartIcon from '@mui/icons-material/PieChart';
import Divider from '@mui/material/Divider';

export default function TemporaryDrawer(props) {
  const { setOpenDrawer, openDrawer } =  props

  const toggleDrawer = (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
    }

    setOpenDrawer(false)
  };

  const redirectTo = (path) => {
    window.location.replace(path);
  }

  const list = () => (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={(e) => toggleDrawer(e)}
      onKeyDown={(e) => toggleDrawer(e)}
    >
      <List sx={{ mt: 4}}>
        <ListItem button onClick={ () => redirectTo("/reports")}>         
          <ListItemIcon>
              <PieChartIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Relatórios de Desempenho" />
        </ListItem>
        <Divider />
        <ListItem button onClick={ () => redirectTo("/logout")}>         
          <ListItemIcon>
              <LogoutIcon color="primary" />
          </ListItemIcon>
          <ListItemText primary="Sair do Sistema" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <React.Fragment>
        <Drawer
        anchor='right'
        open={openDrawer}
        onClose={ (e) => toggleDrawer(e)}
        >
        {list()}
        </Drawer>
    </React.Fragment>
  );
}
