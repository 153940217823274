import React from 'react';
import {Alert, Box} from '@mui/material';
import CalendarIcon from '@mui/icons-material/CalendarMonth'

import Members from './members';

export default function Releases(props) {
  const { releases } = props 
  return (
      <>
        {releases.map((release, index) => {
          return(
            <Box sx={{mt: 2}} key={index}>
              <Alert
                severity="info"
                iconMapping={{
                  info: <CalendarIcon fontSize="inherit" />
                }} >
                  <strong>
                  {release.start_date}
                  {" - "} {release.start_time} às {release.end_time}
                  </strong>
              </Alert>
              <Members members={release.members}/>
            </Box>
          )
        })}
    </>
  );
}
