import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Typography} from '@mui/material';

export default function Releases(props) {
  const { members } = props 
  return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mb: 2 }} size="small" aria-label="Members table">
                <TableBody>
                    {members.map((member, index) => {
                        return(
                            <TableRow key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell style={{ width: "8%" }} align="right">{member.occupation_name}</TableCell>
                                <TableCell align="left">
                                    <Typography variant="caption" component="h1">
                                        {member.job_title}{" "}{member.origin}
                                        {" Mat. "} {member.registration}
                                        <strong> {member.name} </strong>
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
          </Table>
      </TableContainer>
  );
}
