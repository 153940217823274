import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import {Paper} from '@mui/material';

export default function Works(props) {
  const { works, total } = props
  //Object.keys(works) 
  return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650, mb: 2 }} size="small" aria-label="Members table">
                <TableBody>
                    {Object.keys(works).map((key, index) => {
                        return(
                            <TableRow key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">
                                {key}: <strong>{works[key]}</strong>
                                </TableCell>
                            </TableRow>
                        )
                    })}
                    <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell align="left">
                                    <strong>TOTAL DE COTAS: {total}</strong> 
                                </TableCell>
                    </TableRow>
                </TableBody>
          </Table>
      </TableContainer>
  );
}
