import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Groups from './Groups'
import Questions from './Questions'
import UserScore from '../evaluations/UserScore'
import { Typography, Breadcrumbs, Link } from '@mui/material'
import HomeIcon from '@mui/icons-material/Home';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <div style={{ marginTop: 15 }}>
          {children}
        </div>
      )}
    </div>
  );
}

const Index = () => {
  const [activeTab, setActiveTab] = React.useState(0);

  const handleChangeTab = (event, newValue) => {
      setActiveTab(newValue);
  };

  return (
    <div style={{ marginTop: 10 }}>
        <Breadcrumbs aria-label="breadcrumb"  sx={{ mb: 5}}>
          <Link underline="hover" color="inherit" href="/" sx={{ display: 'flex', alignItem: 'center' }}>
              <HomeIcon sx={{ mr: 0.5 }} />
              Página inicial
          </Link>
          <Link underline="hover" color="inherit" href="/evaluations" sx={{ display: 'flex', alignItems: 'center' }}>
              Avaliação 360
          </Link>
          <Typography color="text.primary">Relatórios de Desempenho</Typography>
        </Breadcrumbs>

        <Tabs value={activeTab} 
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="Report">
          <Tab label="Seu Desempenho Geral" {...a11yProps(0)} />
          <Tab label="Seu Desempenho por Perguntas-Chave" {...a11yProps(1)} />
          <Tab label="Seu Desempenho por Grupo" {...a11yProps(2)} />
        </Tabs>

        <TabPanel value={activeTab} index={0} >
          <UserScore />
        </TabPanel>
        <TabPanel value={activeTab} index={1}>
          <Questions />
        </TabPanel>
        <TabPanel value={activeTab} index={2}>
          <Groups />
        </TabPanel>
    </div>
  );
}

export default Index;