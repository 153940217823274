import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useParams } from "react-router-dom"
import { Divider, Breadcrumbs, Link, Chip, Stack, Skeleton } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home'

import { fetchWorkScheduleData } from '../../api/Constants';
import Releases from '../../sections/works/releases';
import Annotations from '../../sections/works/annotations';


export default function WorkShow() {
    const [work, setWork] = useState(null)
    const [releases, setReleases] = useState([])
    const [annotations, setAnnotations] = useState([])
    const { id } = useParams();

    useEffect(() => {  
        fetchWorks()
    }, []);
    
    const fetchWorks = async () => {
        const patch = `/api/works/${id}`;
        const data = await fetchWorkScheduleData(patch)

        if (data) {
            setWork(data)
            setReleases(data.releases)
            setAnnotations(data.annotations)
        }
    }

  return (
    <>
        <Breadcrumbs aria-label="breadcrumb" sx={{ mb: 2 }}>
            <Link underline="hover"
                color="inherit"
                href="/"
                sx={{ display: 'flex', alignItems: 'center' }}>
                <HomeIcon sx={{ mr: 0.5 }} />
                    Página inicial
            </Link>
            <Link underline="hover"
                color="inherit"
                href="/works"
                sx={{ display: 'flex', alignItems: 'center' }}>
                    Escalas
            </Link>
            <Typography color="text.primary">{work ? work.title : ""}</Typography>
        </Breadcrumbs>
        <Card elevation={3} sx={{mb: 4}}>
            <CardContent>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                    >
                    <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                        {work ? work.job_type_name : <Skeleton variant="rectangular" width={210}/>}
                    </Typography>
                    <Chip label={work ? work.period : "------"} 
                        color="primary" size="small" />
                </Stack>
                
                <Typography variant="h5" component="div">
                    {work ? work.title : <Skeleton variant="rectangular" width={510}/>}
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {work ? work.description : <Skeleton variant="rectangular" width={410}/>}
                </Typography>
                <Divider />
                <Releases releases={releases} />
                <Annotations annotations={annotations} />
            </CardContent>
        </Card>
    </>
  );
}
